import { render, staticRenderFns } from "./TableCellWarning.vue?vue&type=template&id=64d8a66b"
import script from "./TableCellWarning.vue?vue&type=script&setup=true&lang=ts"
export * from "./TableCellWarning.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./TableCellWarning.vue?vue&type=style&index=0&id=64d8a66b&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Copy: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Copy/Copy.vue').default,ChromeTooltip: require('/app/components/Chrome/ChromeTooltip.vue').default})
