// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_mHoT4{display:flex;flex-direction:column;height:100%}.control_2Hvbl{cursor:pointer;display:flex;transition-duration:var(--transition-duration-shortest);transition-property:transform;transition-timing-function:ease-in-out}.control_2Hvbl:hover{transform:scale(1.15)}.row_wqgOW{display:flex;height:100%;width:100%}.row_wqgOW:not(:last-child){margin-bottom:1px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_mHoT4",
	"control": "control_2Hvbl",
	"row": "row_wqgOW"
};
module.exports = ___CSS_LOADER_EXPORT___;
